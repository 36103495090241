import React from 'react';
import { Helmet } from 'react-helmet';
import {getSrc} from "gatsby-plugin-image";

function SEO({ videoPageTableData }) {
  const seoData = {};

  seoData.modelName = `${videoPageTableData.Header_Title_Part_One} ${videoPageTableData.Header_Title_Part_Two}`;

  if (videoPageTableData.Website) {
    seoData.website = videoPageTableData.Website;
  }

  if (videoPageTableData.SEO_Description) {
    seoData.description = videoPageTableData.SEO_Description;
  }

  if (videoPageTableData.SEO_Image && !!videoPageTableData.SEO_Image.localFiles) {
    seoData.image = getSrc(videoPageTableData.SEO_Image.localFiles[0].childImageSharp);
  }

  return (
    <Helmet>
      <title>{seoData.modelName}</title>
      {seoData.description && (
        <meta property="og:description" content={seoData.description}></meta>
      )}
      {seoData.image && (
        <meta property="og:image" content={seoData.image}></meta>
      )}
      <meta property="og:url" content={seoData.website} />
      <meta name="twitter:title" content={seoData.modelName} />
      {seoData.description && (
        <meta name="twitter:description" content={seoData.description} />
      )}
      {seoData.image && <meta name="twitter:image" content={seoData.image} />}
    </Helmet>
  );
}
export default SEO;
